import { IBaseReducer } from '../BaseReducer'
import { IBaseGetParams } from '../Global'
import { IDay } from '../Day'
import { ITimesheet } from '../Timesheet'
import { TypeOrNull } from '../Common'
import { IFollow } from '../Follow'
import { IBusinessEntity } from 'models/BusinessEntity'
import { IGigType } from 'models/GigType'

export enum SHIFT_FOLLOWED {
  NOT_FOLLOWED,
  FOLLOWED,
}

export enum SHIFT_AUTO_ACCEPT_STATUS {
  ACCEPT,
  AUTO_ACCEPT,
  SELECTIVE_AUTO_ACCEPT,
}

export enum DISTANCE_METRIC {
  MILES = 'miles',
}

export enum SHIFT_STATUS {
  DRAFT,
  POSTED,
}

export enum SHIFT_TYPE {
  SINGLEDAY,
  MULTIDAY,
}

export enum SHIFT_PROGRESS {
  NOT_STARTED,
  ACTIVE,
  FINISHED,
  NOT_FINISHED,
  ALL,
}

export interface IShiftIncludeParams {
  include?:
    | {
        gig_type?: Record<any, any>
        timesheets?: Record<any, any>
        followed_by_user?: Record<any, any>
      }
    | (
        | 'gig_type'
        | 'timesheets'
        | 'followed_by_user'
        | 'business.business_setting'
        | 'business.vendor_management_system'
        | 'gig_type.gig_type_categories'
      )[]
}

export interface IShift {
  id: number
  property_id: number
  gig_type_id: number
  description_short: string
  description: string
  description_rich_text: string
  date_start: Date
  date_end: Date
  hour_start: string
  hour_end: string
  fancy_date_start: Date
  fancy_date_end: Date
  fancy_time: Date
  time_start: string
  time_end: string
  pay_value: string
  estimated_pay_value: string
  break: number
  positions: number
  available_positions: number
  type: SHIFT_TYPE
  special_instructions: string
  special_instructions_rich_text: string
  what_to_wear: string
  what_to_wear_rich_text: string
  travel_tips: string
  travel_tips_rich_text: string
  auto_accept: boolean
  auto_accept_by_certificates: boolean
  auto_accept_by_worked_before: boolean
  auto_accept_by_favored: boolean
  auto_accept_by_onboarded: boolean
  training_mode: boolean
  fixed_time?: null | boolean
  to_hire: boolean
  shift?: null
  min_age: number
  progress: SHIFT_PROGRESS
  timetracker_clock_in_only: boolean
  status: SHIFT_STATUS
  applicants: {
    pending_count: number
    accepted_count: number
    confirmed_count: number
  }
  suggested_pay_rate: number
  arrival_instructions: string
  arrival_instructions_rich_text: string
  physical_requirement: string[]
  how_many_weeks: number
  weekly_on: number[]
  stats: {
    active_punch_cards_count: number
    punch_cards_count: number
  }
  timesheets: {
    data: ITimesheet[]
  }
  followed_by_user: TypeOrNull<IFollow>
  amount_lifted_frequently: TypeOrNull<number>
  amount_lifted_occasionally: TypeOrNull<number>
  amount_of_time_spent_on_feet: TypeOrNull<string>
  gig_identifier: TypeOrNull<string>
  hide_auto_accept_certificates: boolean
  is_bench_connect_gig: boolean
  business?: IBusinessEntity
  next_working_day_time_start: string
  gig_type: IGigType
  description_disclaimer?: string

  /**
   * Start Deprecated
   */

  property_name: string
  property_address: string
  property_latitude: string
  property_longitude: string
  property_timezone: string
  property_rating: string
  property_timetracker_clock_in_only: string
  business_id: number
  business_industry_id: number
  business_name: string
  required_documents_count: number
  pending_applicants_count: number
  accepted_applicants_count: number
  confirmed_applicants_count: number
  punch_cards_count: number
  not_declined_punch_cards_count: number
  active_punch_cards_count: number
  next_day_id: number
  next_day_timestamp_start: string
  drug_screening_url: string
  certificate_ids_with_prices: any
  job_title: string

  /**
   * End Deprecated
   */
}

export interface IGetShiftsParamsBase extends IBaseGetParams {
  status?: SHIFT_STATUS
  user_id?: number
  property_id?: number
  timesheet_id?: number
  progress?: SHIFT_PROGRESS
  sort_by?: string
  longitude?: string
  latitude?: string
  distance?: string
  distance_in?: DISTANCE_METRIC
  filled?: number
  type?: SHIFT_TYPE
  price_start?: string
  price_end?: string
  date_start?: string
  date_end?: string
  time_start?: string
  time_end?: string
  regions?: number[]
  gig_types?: number[]
  properties?: number[]
  property_categories?: number[]
  reviewed?: boolean
  clocked_in?: boolean
  auto_accept?: boolean
  auto_accept_by_certificates?: boolean
  auto_accept_by_worked_before?: boolean
  auto_accept_by_favored?: boolean
  auto_accept_by_onboarded?: boolean
  to_hire?: boolean
  business_id?: number
  featured_gigs_campaign_id?: number
  account_manager_id?: number
  auto_accept_status?: SHIFT_AUTO_ACCEPT_STATUS
  min_pay_value?: number
  max_pay_value?: number
  direct_follow?: boolean
  follow?: SHIFT_FOLLOWED
  follow_by_user_ids?: number[]
  business_ids?: number[]
  industry_ids?: number[]
  user_location_distance?: boolean
  training_mode?: boolean
  unique_identifiers?: boolean
  gig_identifier?: number
  gig_identifier_search?: string
  is_bench_connect_gig?: boolean
  vendor_management_system_id?: TypeOrNull<number>
}

export type IGetShiftsParams = IGetShiftsParamsBase & IShiftIncludeParams

export interface IPostShiftParamsBase {
  property_id: number
  gig_type_id: number
  date_start: Date
  date_end?: Date
  hour_start: string
  hour_end: string
  description: string
  description_short: string
  type: SHIFT_TYPE
  shift?: number
  pay_value: number
  positions: number
  break?: number
  what_to_wear: number
  special_instructions?: string
  arrival_instructions?: string
  travel_tips?: string
  min_age?: number
  min_age_toggle?: boolean
  fixed_time?: boolean
  to_hire?: boolean
  auto_accept?: boolean
  auto_accept_by_certificates?: boolean
  auto_accept_by_worked_before?: boolean
  auto_accept_certificate_ids: number[]
  status: SHIFT_STATUS
  days?: IDay
  amount_lifted_frequently: number
  amount_lifted_occasionally: number
  amount_of_time_spent_on_feet: number
  skip_business_suspended_validation?: boolean
}

export type IPostShiftParams = IPostShiftParamsBase & IShiftIncludeParams

export interface IPutShiftParamsBase {
  property_id?: number
  gig_type_id?: number
  date_start?: Date
  date_end?: Date
  hour_start?: string
  hour_end?: string
  description?: string
  description_short?: string
  type?: SHIFT_TYPE
  shift?: number
  pay_value?: number
  positions?: number
  break?: number
  what_to_wear?: string
  special_instructions?: string
  arrival_instructions?: string
  travel_tips?: string
  min_age?: number
  min_age_toggle?: boolean
  fixed_time?: boolean
  to_hire?: boolean
  auto_accept?: boolean
  auto_accept_by_certificates?: boolean
  auto_accept_by_worked_before?: boolean
  auto_accept_by_favored?: boolean
  auto_accept_by_onboarded?: boolean
  auto_accept_certificate_ids?: number[]
  status?: SHIFT_STATUS
  days?: IDay[]
  amount_lifted_frequently?: number
  amount_lifted_occasionally?: number
  amount_of_time_spent_on_feet?: number
  skip_overtime_validation?: boolean
  gig_identifier?: string
}

export type IPutShiftParams = IPutShiftParamsBase & IShiftIncludeParams

export interface IShiftsReducer extends IBaseReducer<IShift[]> {}

export interface ISelectedShiftReducer extends IBaseReducer<IShift> {}

export interface IBulkEditShiftsReducer extends IBaseReducer<IShift[]> {}
