export const FEATURE_TOGGLE_OSHA_ENABLED = import.meta.env.VITE_FEATURE_TOGGLE_OSHA_ENABLED === '1'

export const FEATURE_TOGGLE_PENDING_VERIFICATIONS =
  import.meta.env.VITE_FEATURE_TOGGLE_PENDING_VERIFICATIONS === '1'

export const FEATURE_TOGGLE_ADMIN_FOLLOW_SHIFTS =
  import.meta.env.VITE_FEATURE_TOGGLE_ADMIN_FOLLOW_SHIFTS === '1'

export const FEATURE_TOGGLE_TIMETRACKER = import.meta.env.VITE_FEATURE_TOGGLE_TIMETRACKER === '1'

export const FEATURE_TOGGLE_PENDING_TERMINATIONS =
  import.meta.env.VITE_FEATURE_TOGGLE_PENDING_TERMINATIONS === '1'

export const FEATURE_TOGGLE_SHIFT_IDENTIFIER =
  import.meta.env.VITE_FEATURE_TOGGLE_SHIFT_IDENTIFIER === '1'

export const FEATURE_TOGGLE_IN_APP_ONBOARDING =
  import.meta.env.VITE_FEATURE_TOGGLE_IN_APP_ONBOARDING === '1'

export const FEATURE_TOGGLE_CONFETTI = import.meta.env.VITE_FEATURE_TOGGLE_CONFETTI === '1'

export const FEATURE_TOGGLE_UNIVERSAL_TEMPLATES =
  import.meta.env.VITE_FEATURE_TOGGLE_UNIVERSAL_TEMPLATES === '1'

export const FEATURE_TOGGLE_PUNCH_CARD_TRANSFERS =
  import.meta.env.VITE_FEATURE_TOGGLE_PUNCH_CARD_TRANSFERS === '1'

export const FEATURE_TOGGLE_PENDING_APPLICANTS_CAP =
  import.meta.env.VITE_FEATURE_TOGGLE_PENDING_APPLICANTS_CAP === '1'

export const FEATURE_TOGGLE_WORK_WITH_FRIENDS =
  import.meta.env.VITE_FEATURE_TOGGLE_WORK_WITH_FRIENDS === '1'

export const FEATURE_TOGGLE_POWER_SEARCH = import.meta.env.VITE_FEATURE_TOGGLE_POWER_SEARCH === '1'

export const FEATURE_TOGGLE_GEOFENCING_CLOCK_IN_OUT =
  import.meta.env.VITE_FEATURE_TOGGLE_GEOFENCING_CLOCK_IN_OUT === '1'

export const FEATURE_TOGGLE_GAMIFICATION_SYSTEM =
  import.meta.env.VITE_FEATURE_TOGGLE_GAMIFICATION_SYSTEM === '1'

export const FEATURE_TOGGLE_INVITE_UPSHIFTERS =
  import.meta.env.VITE_FEATURE_TOGGLE_INVITE_UPSHIFTERS === '1'

export const FEATURE_TOGGLE_VENDOR_MANAGEMENT_SYSTEMS =
  import.meta.env.VITE_FEATURE_TOGGLE_VENDOR_MANAGEMENT_SYSTEMS === '1'

export const FEATURE_TOGGLE_PAYMENTS_SYSTEMS_REVAMP =
  import.meta.env.VITE_FEATURE_TOGGLE_PAYMENTS_SYSTEMS_REVAMP === '1'

export const FEATURE_TOGGLE_SEGMENT = import.meta.env.VITE_FEATURE_TOGGLE_SEGMENT === '1'

export const FEATURE_TOGGLE_INVITE_APPLICANTS_REVAMP =
  import.meta.env.VITE_FEATURE_TOGGLE_INVITE_APPLICANTS_REVAMP === '1'

export const FEATURE_TOGGLE_ADD_SKILL_BASED_CHALLENGES =
  import.meta.env.VITE_FEATURE_TOGGLE_ADD_SKILL_BASED_CHALLENGES === '1'

export const FEATURE_TOGGLE_MY_EXPERIENCE =
  import.meta.env.VITE_FEATURE_TOGGLE_MY_EXPERIENCE === '1'

export const FEATURE_TOGGLE_LOCATION_SPECIFIC_BLOCKING =
  import.meta.env.VITE_FEATURE_TOGGLE_LOCATION_SPECIFIC_BLOCKING === '1'

export const FEATURE_TOGGLE_WORK_OFF_YOUR_STRIKES =
  import.meta.env.VITE_FEATURE_TOGGLE_WORK_OFF_YOUR_STRIKES === '1'

export const FEATURE_TOGGLE_UPSHIFTER_EDITING_HOURS =
  import.meta.env.VITE_FEATURE_TOGGLE_UPSHIFTER_EDITING_HOURS === '1'

export const FEATURE_TOGGLE_MULTIDAY_REVAMP =
  import.meta.env.VITE_FEATURE_TOGGLE_MULTIDAY_REVAMP === '1'

export const FEATURE_TOGGLE_RESTRICT_LAST_MINUTE_CANDIDATE_ADDITIONS_IN_THE_VMS =
  import.meta.env.VITE_RESTRICT_LAST_MINUTE_CANDIDATE_ADDITIONS_IN_THE_VMS === '1'

// feature toggle for Onboarded SCM only
export const FEATURE_TOGGLE_VMS_ONBOARDING_MANAGEMENT =
  import.meta.env.VITE_FEATURE_TOGGLE_VMS_ONBOARDING_MANAGEMENT === '1'

export const FEATURE_TOGGLE_SMS_CLOCK_IN_SETTING =
  import.meta.env.VITE_FEATURE_TOGGLE_SMS_CLOCK_IN_SETTING === '1'

/**
 * We're using this feature toggle for when BND
 * sends us events through a web socket.
 */
export const FEATURE_TOGGLE_WEBSOCKETS = import.meta.env.VITE_FEATURE_TOGGLE_WEBSOCKETS === '1'

/**
 * We're using this feature toggle for when BND starts sending us keys
 * in the web sockets response rather than the whole object that needs to
 * be updated. With this approach, we'll do a GET request with the provided key
 * so we can take the data, no matter how big, that we're tracking through the
 * web socket.
 */
export const FEATURE_TOGGLE_WEBSOCKETS_LINKING =
  import.meta.env.VITE_FEATURE_TOGGLE_WEBSOCKETS_LINKING === '1'
