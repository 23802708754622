export const HTTPS = import.meta.env.HTTPS
export const PORT = import.meta.env.PORT
export const GENERATE_SOURCEMAP = import.meta.env.GENERATE_SOURCEMAP
export const NODE_ENV = import.meta.env.NODE_ENV
export const ENV_NAME = import.meta.env.VITE_ENV_NAME
export const SKIP_PREFLIGHT_CHECK = import.meta.env.SKIP_PREFLIGHT_CHECK
export const DATADOG_APP_KEY = import.meta.env.VITE_DATADOG_APP_KEY
export const DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN
export const SEGMENT_APP_KEY = import.meta.env.VITE_SEGMENT_APP_KEY
export const API_HOST = import.meta.env.VITE_API_HOST
export const API_VERSION = import.meta.env.VITE_API_VERSION
export const VERSION = import.meta.env.VITE_VERSION
export const SHOW_SIDEBAR = import.meta.env.VITE_SHOW_SIDEBAR
export const NO_CALL_NO_SHOW_ID = import.meta.env.VITE_NO_CALL_NO_SHOW_ID
export const TIMESHEET_FALSIFICATION_ID = import.meta.env.VITE_TIMESHEET_FALSIFICATION_ID
export const STRIPE_PK_KEY = import.meta.env.VITE_STRIPE_PK_KEY
export const ADMIN_PORTAL_LINK = import.meta.env.VITE_ADMIN_PORTAL_LINK
export const BUSINESS_PORTAL_LINK = import.meta.env.VITE_BUSINESS_PORTAL_LINK
export const UPSHIFTER_PORTAL_LINK = import.meta.env.VITE_UPSHIFTER_PORTAL_LINK
export const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PK_KEY as string
export const VERSION_COMMIT_HASH = import.meta.env.VITE_VERSION_COMMIT_HASH
export const LIGHT_INDUSTRY_IDS = import.meta.env.VITE_LIGHT_INDUSTRY_IDS
export const LAST_MINUTE_ADDITIONS_ALLOWED_USERS = import.meta.env
  .VITE_LAST_MINUTE_ADDITIONS_ALLOWED_USERS
export const HUBSPOT_ENV_ID = import.meta.env.VITE_HUBSPOT_ENV_ID
export const PUSHER_KEY = import.meta.env.VITE_PUSHER_KEY
export const PUSHER_AUTHORIZATION_ENDPOINT = import.meta.env.VITE_PUSHER_AUTHORIZATION_ENDPOINT
export const PUSHER_CLUSTER = import.meta.env.VITE_PUSHER_CLUSTER
